// node_module dependencies
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'

// Base Page
import BasePage from '@/pages/Base'

// Local Components
import ProductDetail from '@/containers/ProductDetail/ProductDetail.vue'

// Services
import ProductService from '@/services/Products'
import RequestGoodsService from '@/services/RequestGoods'

@Component({ components: { ProductDetail } })
export default class RequestGoodsResponse extends BasePage {
  constructor() {
    super()
  }

  urlParamsId: string | number = ''

  // Search
  searchLoading: boolean = false
  searchInput: string = ''
  searchLabel: string = 'Search here...'
  searchHint: string = 'Search Anything Here'
  searchIcon: string = 'search'

  // Search Recommendation
  searchRecommendationLoading: boolean = false
  searchRecommendationInput: string = ''
  searchRecommendationLabel: string = 'Search here...'
  searchRecommendationHint: string = 'Search Anything Here'
  searchRecommendationIcon: string = 'search'

  // Table Product
  tableHeaders: object[] = [
    {
      text: 'ID',
      align: 'left',
      sortable: true,
      value: 'id'
    },
    {
      text: 'Product Name',
      align: 'left',
      sortable: true,
      value: 'title'
    },
    {
      text: 'Price',
      align: 'left',
      sortable: true,
      value: 'price'
    },
    {
      text: 'Quantity',
      align: 'left',
      sortable: true,
      value: 'quantity'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'status'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: number | string = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'id',
    page: 1,
    rowsPerPage: 25,
    descending: false,
    totalItems: 0
  }
  tableLoading: boolean = false

  // Table Recommendation
  tableRecommendationHeaders: object[] = [
    {
      text: 'ID',
      align: 'left',
      sortable: true,
      value: 'id'
    },
    {
      text: 'Product Name',
      align: 'left',
      sortable: true,
      value: 'title'
    },
    {
      text: 'Price',
      align: 'left',
      sortable: true,
      value: 'price'
    },
    {
      text: 'Quantity',
      align: 'left',
      sortable: true,
      value: 'quantity'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'status'
    }
  ]
  tableRecommendationItems: object[] = []
  tableRecommendationRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tableRecommendationPagination: any = {
    rowsPerPage: 25,
    include: 'images'
  }
  tableRecommendationLoading: boolean = false

  // Requested Product Data
  currentRequest: object = {}

  @Watch('tablePagination', { deep: true })
  @Watch('searchInput')
  onChanged() {
    this.$vuetify.goTo(0)
    this.getProductLists()
  }

  mounted() {
    this.urlParamsId = this.$route.params.id
    this.getCurrentRequestData()
    this.getRequestGoodsRecommendationLists()
    this.getProductLists()
  }

  getCurrentRequestData() {
    this.tableLoading = true
    RequestGoodsService.getSingleRequest(this.urlParamsId)
      .then(response => {
        this.currentRequest = {
          requestId: response.id,
          customerName: response.user.full_name,
          requestDate: response.created_at,
          goods: response.name,
          currency: response.currency,
          min_price: response.min_price,
          max_price: response.max_price,
          status: response.status,
          image: response.file,
          description: response.description == '' ? 'No description provided' : response.description
        }
        this.tableLoading = false
      })
      .catch(error => this.catchHandler(error))
  }

  getProductLists() {
    const opts: any = {
      params: {
        page: this.tablePagination.page,
        limit: this.tablePagination.rowsPerPage,
        sortedBy: this.tablePagination.descending ? 'desc' : 'asc',
        orderBy: this.tablePagination.sortBy,
        include: 'images'
      }
    }
    if (this.searchInput != '') opts.params.search = this.searchInput

    this.tableLoading = true
    ProductService.getProductsLists(opts)
      .then(response => {
        this.tableTotalItems = response.meta.pagination.total
        this.tableItems = []

        forEach(response.data, dataProduct => {
          let product: any = {
            value: false,
            productName: dataProduct.title,
            productId: dataProduct.id,
            currency: dataProduct.currency,
            price: dataProduct.price,
            quantity: dataProduct.quantity,
            status: false,
            images: [],
            description: dataProduct.description == '' ? 'No description provided' : dataProduct.description
          }

          forEach(dataProduct.images.data, dataImages => {
            product.images.push({
              id: dataImages.id,
              image: dataImages.file
            })
          })

          forEach(this.tableRecommendationItems, (status: any) => {
            if (dataProduct.id == status.id) product.status = true
          })

          this.tableItems.push(product)
        })

        this.tableLoading = false
      })
      .catch(error => this.catchHandler(error))
  }

  getRequestGoodsRecommendationLists() {
    this.tableRecommendationLoading = true
    const opts = {
      params: {
        include: 'images'
      }
    }
    RequestGoodsService.getRequestGoodsRecommendationLists(this.urlParamsId, opts)
      .then(response => {
        this.tableRecommendationItems = response.data
        this.tableRecommendationLoading = false
      })
      .catch(error => this.catchHandler(error))
  }

  addRecommendation(productId: string | number) {
    this.tableLoading = true
    this.tableRecommendationLoading = true

    RequestGoodsService.addRecommendation(this.urlParamsId, { products: [`${productId}`] })
      .then(data => {
        this.getRequestGoodsRecommendationLists()
        this.getProductLists()
        this.tableLoading = false
        this.tableRecommendationLoading = false
      })
      .catch(error => this.catchHandler(error))
  }

  cancelRecommendation(productId: string | number) {
    this.tableRecommendationLoading = true

    RequestGoodsService.cancelRecommendation(this.urlParamsId, { products: [`${productId}`] })
      .then(data => {
        this.getRequestGoodsRecommendationLists()
        this.getProductLists()
        this.tableRecommendationLoading = false
      })
      .catch(error => this.catchHandler(error))
  }
}
